import {defineStore} from "pinia"
import api from '@/api'
import { HeaderStatistic } from '@/models'
import {useUserStore} from "@/stores/User/user"

export const useHeaderStore = defineStore({
	id: 'headerStore',
	state: () => ({
		cardsData: [] as HeaderStatistic[],
		mgerActiveRegistered: 0 as Number
	}),
	actions: {
		async getHeaderData(): Promise<boolean> {
			try {
				const response = await api.headerApi.getHeaderData()
				if (response.status === 200) {
					if(useUserStore().user?.is_mger) {
						const data = response.data
						this.mgerActiveRegistered = response.data.mger.count
						delete data['mger']
						this.cardsData = data
						return true
					}
					this.cardsData = response.data
					return true
				}
			}
			catch (error) {
				console.error(error)
			}
			return false
		}
	}
})
