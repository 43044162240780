import {defineStore} from "pinia"
import {ChartItem} from '@/models/chart'
import api from '@/api'
import { isNull } from 'lodash'

// Helper function for date assignment
const assignDate = (dateString: string | null): Date => {
	return isNull(dateString) ? new Date() : new Date(dateString)
}

export const useChartStore = defineStore({
	id: 'chartStore',
	state: () => ({
		chartData: [] as ChartItem[],
		chartDateFrom: undefined as Date | undefined,
		chartDateTo: undefined as Date | undefined,
		cumulative: true,
		isLoading: true
	}),
	actions: {
		async getChartData() {
			try {
				const date_from = this.chartDateFrom
				const date_to = this.chartDateTo
				const response = await api.chartApi.getChart(date_from, date_to, this.cumulative)
				
				if(response.status !== 200) {
					return false
				}
				this.chartData = response.data
				this.isLoading = false
				return response.status
			} catch (err: any) {
				console.error(err)
				return false
			}
		},
		setChartDates(dateFrom: string, dateTo: string | null) {
			this.chartDateFrom = assignDate(dateFrom)
			this.chartDateTo = assignDate(dateTo)
		}
	}
})
