<template>
	<div class="map position-relative">
		<button
			v-if="!user?.region"
			class="position-absolute"
			style="z-index: 99; top: 0; left: 0"
			:class="regionStore.currentRegion.title !== 'Все регионы' ? 'all_regions_button' : 'all_regions_button-active'"
			@click="regionStore.changeRegionState('Все регионы')"
		>
			Все регионы
		</button>
		<highcharts
			ref="chart"
			:constructor-type="'mapChart'"
			:options="mapOptions"
			style="height: 100%; max-width: 100%"
			class="pa-1"
		/>
	</div>
</template>

<script lang="ts" setup>
/**
 * @file Описание компонента для отображения карты России с использованием HighCharts
 */
import { computed, ref } from "vue"
import proj4 from "proj4"
import { Chart } from "highcharts-vue"
import dataJson from "@/helpers/newMapFinal.json"
import hcKeys from "@/helpers/hcKeys.json"
import { useRatingStore } from '@/stores/tops'
import { RegionRatingItem } from '@/models/rating'
import { useRegionsStore } from "@/stores/regions"
import stores from '@/stores'
import { Region } from '@/models'
import { isUndefined } from 'lodash'

const user = computed(() => {
	return stores.useUserStore().user
})

const store = useRatingStore()

const chart = ref<InstanceType<typeof Chart> | null>(null)

/** @description Данные карты в формате GeoJson */
const features = [...dataJson.features]

/**
 * @description Вычисляемое свойство, возвращающее данные для отображения серий на карте
 * @returns {object} Array of objects representing the data points and keys for the HighCharts series
 */
const mapSeries = computed(() => {
	const _allRegions = [...hcKeys]
	const _topRegions = [] as string[]
	const _outsideRegions = [] as string[]
	const _currentRegion = [] as string[]
	const top = store.top
	const outside = store.outside

	/**
	 * @description Функция для удаления ключа из массива
	 * @param {string[]} array - Массив, из которого удаляется ключ
	 * @param {string[] | string} hcKeys - Ключ или массив ключей для удаления из массива
	 */
	const removeRegion = (array: string[], hcKeys: string[] | string) => {
		const deleteKey = (removedKey: string) => { array = array.filter((_key) => removedKey !== _key) }
		if (Array.isArray(hcKeys)) {
			hcKeys.forEach((key) => {
				deleteKey(key)
			})
		} else deleteKey(hcKeys)
	}

	/**
	 * @description Функция для обновления массива региона (Все, топ, аутсайд)
	 * @param {string[]} array - Массив региона, который обновляется
	 * @param {RegionRatingItem[] | Region[]} storeType - Тип данных в хранилище (рейтинг региона или регион)
	 */
	const updateArrayRegion = (array: string[], storeType: RegionRatingItem[] | Region[]) => {
		array.slice(0, array.length)
		storeType.forEach((_region) => {
			const regionFromFeatures = features.find((fullRegion) => fullRegion.properties.name.toLowerCase() === _region.title.toLowerCase())
			if (regionFromFeatures === undefined) return
			array.push(regionFromFeatures.properties['hc-key'])
		})
		const allArrays = [_allRegions, _topRegions, _outsideRegions]
		for (const allArraysKey of allArrays) {
			if (allArraysKey.toString() !== array.toString()) removeRegion(allArraysKey, array)
		}
	}
	updateArrayRegion(_topRegions, top)
	updateArrayRegion(_outsideRegions, outside)
	if (!isUndefined(stores.useRegionsStore().currentRegion)) {
		if (stores.useRegionsStore().currentRegion.id !== -1) updateArrayRegion(_currentRegion, [stores.useRegionsStore().currentRegion])
	}
	return [
		{
			"name": "All regoins",
			keys: ["hc-key", "value"],
			colorAxis: 0,
			"data": [
				..._allRegions
			].map(name => ([name, getRegionData()]))
		},
		{
			"name": "Top regions",
			keys: ["hc-key", "value"],
			colorAxis: 3,
			"data": [
				..._topRegions
			].map(name => ([name, getRegionData()]))
		},
		{
			"name": "Outside regions",
			keys: ["hc-key", "value"],
			colorAxis: 2,
			"data": [
				..._outsideRegions
			].map(name => ([name, getRegionData()]))
		},
		{
			"name": "Current region",
			keys: ["hc-key", "value"],
			colorAxis: 1,
			"data": [
				..._currentRegion
			].map(name => ([name, getRegionData()]))
		}
	]
})


/**
 * @description Функция, возвращающая данные региона
 * @returns {number} Возвращает 0 в данной реализации
 */
const getRegionData = (): number => {
	return 0
}
const regionStore = useRegionsStore()

/**
 * @description Настройки для компонента HighCharts
 * @returns {object} Объект с настройками HighCharts
 */
const mapOptions = ref({
	chart: {
		map: "Russia",
		proj4: proj4,
		animation: false,
	},
	accessibility: {
		enabled: false
	},
	title: {
		text: ""
	},
	subtitle: {
		text: ""
	},
	mapNavigation: {
		enabled: true,
		buttonOptions: {
			verticalAlign: "bottom"
		}
	},
	xAxis: {
		min: -3600,
		max:
			12000
	},
	yAxis: {
		min: -7200,
		max:
			-6200
	},
	tooltip: {
		style: {
			color: "#000000",
			fontSize: "1rem"
		},
		backgroundColor: "#F2F9FD",
		borderColor: "transparent",
		useHTML: true,
		shadow: false,
		headerFormat: "",
		pointFormat: "<div style=\"width: 100%; text-align: center\"><p>{point.name}</p></div>"
	},
	plotOptions: {
		map: {
			states: {
				hover: {
					borderColor: "#FCFF6A",
					borderWidth: 1,
					brightness: 0
				},
				inactive: {
					borderColor: "#fff",
					borderWidth: 1,
					opacity: 1
				}
			},
			allAreas: false,
			inactiveOtherPoints: false,
			borderColor: "#999",
			borderWidth: 0.5,
			keys: ["hc-key", "value"],
			joinBy: ["hc-key", "hc-key"],
			series: {
				states: {
					hover: {
						enabled: false
					}
				},
				animation: {
					duration: 1500,
					easing: 'fade',
				}
			},
			point: {
				events: {
					click: function(e: any) {
						regionStore.changeRegionState(e.point.name)
					}
				}
			}
		}
	},
	legend: {
		enabled: false
	},
	exporting: {
		enabled: false
	},
	credits: {
		enabled: false
	},
	colorAxis: [
		{
			minColor: "#365E9C",
			maxColor: "#365E9C"
		},
		{
			minColor: "#00B4F2",
			maxColor: "#00B4F2"
		},
		{
			minColor: "#F04941",
			maxColor: "#F04941"
		},
		{
			minColor: "#30b632",
			maxColor: "#30b632"
		}
	],
	series: mapSeries
})
</script>

<style lang="scss" scoped>
	.all_regions_button {
		font-size: 0.6rem;
		background-color: #fff;
		line-height: 1rem;
		font-weight: 400;
		border-radius: 10px;
		margin: 5px;
		padding: 5px;
		color: var(--color-main-text);
		border: 1px solid var(--color-text-positive);
		transition: var(--transition-time) ease-in-out;
		&-active {
			font-size: 0.6rem;
			margin: 5px;
			padding: 5px;
			line-height: 1rem;
			font-weight: 400;
			border-radius: 10px;
			color: white;
			transition: var(--transition-time) ease-in-out;
			background: var(--color-text-positive);
		}
		&:disabled {
			border: 1px solid var(--color-disable);
			color: var(--color-text-sidebar-disabeld);
			background: var(--color-disable);
		}
	}
	.map {
		height: 100%;
	}
</style>
