import {defineStore} from "pinia"
import {Post} from '@/models/posts'
import api from '@/api'

export const useReportsStore = defineStore({
	id: 'reportsStore',
	state: () => ({
		vkList: {
			posts: [] as Post[],
			currentPage: 0,
			totalPages: -1,
			isLoading: true
		},
		okList: {
			posts: [] as Post[] | null,
			currentPage: 0,
			totalPages: -1,
			isLoading: true
		},
		otherList: {
			posts: [] as Post[],
			currentPage: 0,
			totalPages: -1,
			isLoading: true
		}
	}),
	actions: {
		async getPosts(type: 'vk' | 'ok' | 'other', page = 1, limit = 10, thisIsNew = false) {
			try {
				const res = await api.reportsApi.getPosts(type, page, limit)
				if(res.status !== 200) return false
				const data = {
					posts: thisIsNew ? [...res.data.items] : [...this[`${type}List`].posts, ...res.data.items],
					currentPage: res.data.current_page,
					totalPages: res.data.total_pages
				}
				data.posts.forEach((el) => el['loaded'] === undefined ? el['loaded'] = false : null)
				this[`${type}List`] = {...data, isLoading: false}
				return res.status
			}
			catch (err: any){
				console.log(err)
				return false
			}
		}
	}
})
