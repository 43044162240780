import { createRouter, createWebHistory } from "vue-router"

import TestMainPage from "@/views/MainPage.vue"

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{path: '/', component: TestMainPage, meta: {title: 'Наш кандидат'}}
	]
})
router.beforeEach((to, from, next) => {
	const title = String(to.meta.title)
	if (title && window.document) {
		window.document.title = title
	}
	next()
})
export default router




