<script setup lang="ts">
import {useRegionsStore} from "@/stores/regions"
import closeIcon from '@/assets/img/closeCircleDark.svg'
import {useSidebarStore} from "@/stores/sidebar"

const regionsStore = useRegionsStore()
const sidebarStore = useSidebarStore()

</script>

<template>
	<div class="regionsModal d-flex flex-row">
		<img :src="closeIcon" class="regionsModal__close-icon" @click="sidebarStore.backdropTap()">
		<div class="regionsModal__regions-block grid">
			<div
				v-for="el in regionsStore.regions"
				:key="el.id"
				:class="el.title === regionsStore.currentRegion.title ? 'regionsModal__regions-block_region_active' : 'regionsModal__regions-block_region'"
				@click="regionsStore.changeRegionState(el.id)"
			>
				{{ el.title }}
			</div>
		</div>
		<div class="regionsModal__image-position">
			<div class="regionsModal__image-position_itself">
				<img :src="regionsStore.currentRegion.icon_url" style="margin: auto; width: 60%;">
				<div>{{ regionsStore.currentRegion.title }}</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
	grid-column-gap: 6px;
	grid-row-gap:6px;
	width: 80%;
	height: 100%;
	overflow-x: auto;
	&__btn {
		width: 100%;
		padding: 5px;

		&[aria-current="true"] {
			background: #72C2DD66;
		}
	}
}
.regionsModal {
	background: white;
	height: 80vh;
	margin-top: 20px;
	width: 75vw;
	max-width: 80vw;
	text-overflow: ellipsis;
	position: absolute;
	left: calc(100% + 20px);
	z-index: 300;
	color: var(--color-main-text);
	border-radius: 10px;
	padding: 1rem;
	box-shadow: 0 4px 8px #081E4014;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	&__regions-block {
		column-gap: 30px;
		row-gap: 0px;
		height: auto;
		padding: 5px;
		font-size: .8rem;

		&_region {
			width: fit-content;
			display: flex;
			padding: 8px 10px 8px 10px;
			border-radius: 8px;
			cursor: pointer;
			transition: var(--transition-time) ease-in-out;
			margin: auto 0;

			&:hover {
				background: rgba(114, 194, 221, 0.25);
			}

			&_active {
				width: fit-content;
				display: flex;
				padding: 8px 10px 8px 10px;
				border-radius: 8px;
				cursor: pointer;
				transition: var(--transition-time) ease-in-out;
				background: #72C2DD66;
				margin: auto 0;
			}
		}
	}

	&__image-position {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 2;
		&_itself {
			display: flex;
			flex-direction: column;
			text-align: center;
			gap: 11px;
			text-transform: uppercase;
			font-size: 1.1rem;
		}
	}

	&__close-icon {
		width: 1.2rem;
		height: 1.2rem;
		position: absolute;
		right: 1.5rem;
	}
}
</style>
