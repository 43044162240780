import {defineStore} from "pinia"
import api from '@/api'
import { RegionRatingItem, UserTopItem } from '@/models/rating'

export const useRatingStore = defineStore({
	id: 'ratingStore',
	state: () => ({
		top: [] as RegionRatingItem[],
		outside: [] as RegionRatingItem[],
		user: [] as UserTopItem[],
		buttonsState: {actionsButtonState: true, usersButtonState: false},
		isLoadingRegions: true,
		isLoadingUsers: true,
	}),
	actions: {
		async getRegionChart(filter: string) { // Эта функция не используется вне стора, она нужна для defineGetRegionChart
			try {
				const resp = await api.topsApi.getRegionsCharts(filter)
				this.top = resp.data.top
				this.outside = resp.data.outside
				this.isLoadingRegions = false
				return resp.status
			}
			catch (err: any) {
				return err.status
			}
		},
		async getUserCharts() {
			try {
				const resp = await api.topsApi.getUserCharts()
				this.user = resp.data
				this.isLoadingUsers = false
				return resp.status
			}
			catch (err: any) {
				return err.response.status
			}
		},
		async changeButtonState(type: string, option: any) { // Меняет состояние кнопок
			if(option === 'actions') {
				this.setButtonsState('action')
				await this.defineGetRegionChart()
				return
			}
			this.setButtonsState('user')
			await this.defineGetRegionChart()
		},
		async defineGetRegionChart() { // Вызывает функцию getRegionChart, на основе полученной кнопки
			if(this.buttonsState.actionsButtonState) await this.getRegionChart('reports')
			if(this.buttonsState.usersButtonState) await this.getRegionChart('users')
		},
		setButtonsState(type: string) { // Меняет состояние всех кнопок на true по типу
			if(type === 'action') {
				this.buttonsState.actionsButtonState = true
				this.buttonsState.usersButtonState = false
				return
			}
			this.buttonsState.usersButtonState = true
			this.buttonsState.actionsButtonState = false
		},
		clearRegionsData() {
			this.top = []
			this.outside = []
		}
	}
})
