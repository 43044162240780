<template>
	<div
		:id="source.link"
		class="ma-2 pa-0"
	/>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { Post } from '@/models/posts'

const props = defineProps<{
		source: Post
	}>()

onMounted(() => {
	(function (d: Document, id: string, did: string, st: string) {
		const js: HTMLScriptElement = d.createElement('script')
		js.src = 'https://connect.ok.ru/connect.js'
		js.onload = js.onreadystatechange = function () {
			if (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete') {
				if (!(this as any).executed) {
					(this as any).executed = true
					setTimeout(function () {
						// eslint-disable-next-line no-undef
						(window as any).OK.CONNECT.insertContentWidget(id, did, st)
					}, 0)
				}
			}
		}
		d.documentElement.appendChild(js)
	})(document, props.source.link, props.source.link, '{"topicTextIsFull":1}')
		
})
</script>

<style scoped>

</style>
