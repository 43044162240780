<script setup lang="ts">
import {ref} from "vue"

defineProps({
	amount: {required: false, default: 2}, // 2 default, 3
	firstTab: {required: true, default: 'firstTab'},
	secondTab: {required: true, default: 'secondTab'},
	thirdTab: {required: false, default: 'thirdTab'},
	firstWordGap: {required:false, default: false}
})

const emit = defineEmits([
	'changeButtonState'
])

const firstButton = ref(true)
const secondButton = ref(false)
const thirdButton = ref(false)

const changeValue = (id: string) => {
	switch (id) {
	case 'first': {
		secondButton.value = false
		thirdButton.value = false
		firstButton.value = true
		emit('changeButtonState', id)
		break
	}
	case 'second': {
		firstButton.value = false
		thirdButton.value = false
		secondButton.value = true
		emit('changeButtonState', id)
		break
	}
	case 'third': {
		firstButton.value = false
		secondButton.value = false
		thirdButton.value = true
		emit('changeButtonState', id)
		break
	}
	}
}
</script>

<template>
	<div class="tabs">
		<button
			id="first"
			:class="firstButton ? 'tab-button_active': 'tab-button'"
			style="border-left: none"
			@click="changeValue($event.currentTarget.id)"
		>
			<span v-if="firstWordGap">{{ firstTab.split(' ')[0] }} <br/> {{ firstTab.split(' ').splice(1, firstTab.length - 1).join(' ') }}</span>
			<span v-else>{{ firstTab }}</span>
		</button>
		<button
			v-if="amount === '3'"
			id="second"
			:class="secondButton ? 'tab-button_active': 'tab-button'"
			@click="changeValue($event.currentTarget.id)"
		>
			<span v-if="secondTab">{{ secondTab.split(' ')[0] }} <br/> {{ secondTab.split(' ').splice(1, secondTab.length - 1).join(' ') }}</span>
			<span v-else>{{ secondTab }}</span>
		</button>
		<button
			v-if="amount === '3'"
			id="third"
			:class="thirdButton ? 'tab-button_active': 'tab-button'"
			style="border-right: none"
			@click="changeValue($event.currentTarget.id)"
		>
			<span v-if="thirdTab">{{ thirdTab.split(' ')[0] }} <br/> {{ thirdTab.split(' ').splice(1, thirdTab.length - 1).join(' ') }}</span>
			<span v-else>{{ thirdTab }}</span>
		</button>
	</div>
</template>

<style scoped lang="scss">
.tabs {
	display: flex;
}
.tab-button {
	flex: 1;
	border: 1px solid var(--color-borders);
	margin-top: -1px;
	margin-left: -1px;
	text-transform: uppercase;
	font-size: 0.9rem;
	line-height: 1rem;
	letter-spacing: 2px;
	height: fit-content;
	color: var(--color-text-disabeld);
	border-bottom: 4px solid var(--color-borders);
	text-align: left;
	padding: 15px 20px;
	transition: var(--transition-time);
	&_active {
		flex: 1;
		border: 1px solid var(--color-borders);
		margin-top: -1px;
		margin-left: -1px;
		text-transform: uppercase;
		font-size: 0.9rem;
		letter-spacing: 2px;
		line-height: 1rem;
		height: fit-content;
		color: var(--color-main-text);
		border-bottom: 4px solid var(--color-picked-tab);
		text-align: left;
		padding: 15px 20px;
		transition: var(--transition-time);
	}
}
</style>
