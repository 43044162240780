<template>
	<div class="main-page">
		<MainPageCard
			class="d-flex flex-column"
			height="80vh"
		>
			<Tabs
				first-tab="Посты из вк"
				second-tab="Посты из ОК"
				third-tab="Другие посты"
				:first-word-gap="true"
				amount="3"
				@change-button-state="setPostsState"
			/>
			<div
				class="overflow-y-auto"
				style="flex-grow: 3; position: relative"
			>
				<v-infinite-scroll
					v-if="vkPostsState && !reportsStore.vkList.isLoading"
					style="align-items: center"
					@load="loadMore('vk', $event)"
				>
					<template
						v-for="(item) in reportsStore.vkList.posts"
						:key="item.id"
					>
						<VkPost
							style="min-height: 100px"
							:source="item"
						/>
					</template>
				</v-infinite-scroll>
				<v-infinite-scroll
					v-if="okPostsState && !reportsStore.okList.isLoading"
					@load="loadMore('ok', $event)"
				>
					<template
						v-for="(item) in reportsStore.okList.posts"
						:key="item.id"
					>
						<OkPost
							class="okPost"
							:source="item"
						/>
					</template>
				</v-infinite-scroll>
				<v-infinite-scroll
					v-if="allPostsState && !reportsStore.otherList.isLoading"
					@load="loadMore('other', $event)"
				>
					<template
						v-for="(item) in reportsStore.otherList.posts"
						:key="item.id"
					>
						<div class="main-page__other-reports">
							<WidgetPost v-if="!item.link.search('https://t.me')" :post="item.link"/>
							<a v-else :href="item.link">
								<PostWrapper :name="item.fio" :avatar="item.avatar" :href="item.link" >
									<p style="line-break: anywhere">{{ item.link }}</p>
								</PostWrapper>
							</a>
						</div>
					</template>
				</v-infinite-scroll>
				<div v-if="showSkeleton" class="skeletonForReports">
					<Skeletor v-for="el in 3" :key="el" class="skeletonForReports__itself"/>
				</div>
			</div>
		</MainPageCard>
		<div
			style="gap: 20px;"
			class="parent"
		>
			<MainPageCard class="div1 d-flex flex-column">
				<Tabs
					first-tab="Статистика активности"
					amount="1"
					@change-button-state="setChartState"
				/>
				<graphic-chart
					v-if="actionsChartState"
					style="flex-grow: 3"
				/>
			</MainPageCard>
			<MainPageCard class="div2">
				<MapChart
					:loading="false"
					:active-region="null"
				/>
			</MainPageCard>
		</div>
		<div class="main-page__charts">
			<VerticalTab
				id="userTopState"
				:show-tabs="userTopState"
				@change-show-tabs="changeTopState"
			/>
			<VerticalTab
				id="leadRegionsTopState"
				:show-tabs="leadRegionsTopState"
				@change-show-tabs="changeTopState"
			/>
			<VerticalTab
				id="outsiderRegionsTopState"
				:show-tabs="outsiderRegionsTopState"
				@change-show-tabs="changeTopState"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">

import MainPageCard from "@/components/reusable/MainPageCard.vue"
import Tabs from "@/components/reusable/Tabs.vue"
import {computed, ref} from "vue"
import VerticalTab from "@/components/reusable/VerticalTab.vue"
import VkPost from '@/components/VkPost.vue'
import OkPost from '@/components/OkPost.vue'
import {useReportsStore} from "@/stores/reports"
import MapChart from '@/components/Charts/MapChart.vue'
import GraphicChart from '@/components/Charts/GraphicChart.vue'
import {useRegionsStore} from "@/stores/regions"
import PostWrapper from "@/components/PostWrapper.vue"
import { WidgetPost } from 'televue'
const vkPostsState = ref(true)
const okPostsState = ref(false)
const allPostsState = ref(false)

const setPostsState = (id: string) => {
	switch (id){
	case 'first': {
		okPostsState.value = false
		allPostsState.value = false
		vkPostsState.value= true
		break
	}
	case 'second': {
		allPostsState.value = false
		vkPostsState.value= false
		okPostsState.value = true
		break
	}
	case 'third': {
		okPostsState.value = false
		vkPostsState.value= false
		allPostsState.value = true
		break
	}
	}
}

const actionsChartState = ref(true)
const usersChartState = ref(false)

const setChartState = (id: string) => {
	switch (id){
	case 'first': {
		usersChartState.value = false
		actionsChartState.value = true
		break
	}
	case 'second': {
		actionsChartState.value = false
		usersChartState.value = true
		break
	}
	}
}

const userTopState = ref(true)
const leadRegionsTopState = ref(false)
const outsiderRegionsTopState = ref(false)

const changeTopState = (id: string) => {
	switch (id){
	case 'userTopState':{
		leadRegionsTopState.value = false
		outsiderRegionsTopState.value = false
		userTopState.value = true
		break
	}
	case 'leadRegionsTopState':{
		outsiderRegionsTopState.value = false
		userTopState.value = false
		leadRegionsTopState.value = true
		break
	}
	case 'outsiderRegionsTopState':{
		userTopState.value = false
		leadRegionsTopState.value = false
		outsiderRegionsTopState.value = true
		break
	}
	}
}

const reportsStore = useReportsStore()

const loadMore = async (type: 'vk' | 'ok' | 'other', event) => {
	const _ = reportsStore[`${type}List`]
	const openPage = () => {
		if (event.side === 'start' && +_.currentPage >= 1) return 1
		return +_.currentPage + 1
	}
	if (_.totalPages === _.currentPage || _.totalPages === 0) {
		event.done('empty')
	}
	else {
		const status = await reportsStore.getPosts(type, openPage())
		if (status === 200) event.done('ok')
		else event.done('error')
	}
}

const regionStore = useRegionsStore()
reportsStore.getPosts('ok')
reportsStore.getPosts('vk')
reportsStore.getPosts('other')

regionStore.getRegions()

const showSkeleton = computed(() => {
	if(vkPostsState.value && reportsStore.vkList.isLoading) return true
	if(okPostsState.value && reportsStore.okList.isLoading) return true
	if(allPostsState.value && reportsStore.otherList.isLoading) return true
	return false
	// return true
})
</script>

<style scoped lang="scss">
.parent {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1.5fr 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 10px;
}
.div1 { grid-area: 1 / 1 / 2 / 2; }
.div2 { grid-area: 2 / 1 / 3 / 2; }
.main-page{
	padding: 0 20px 1vh 20px !important;
	width: 100%;
	display: grid;
	grid-template-columns: 0.55fr 1.15fr 0.41fr;
	grid-column-gap: 20px;
	grid-row-gap: 0;

	&__charts {
		height: 80vh;
		display: flex;
		flex-direction: column;
		gap: 13px;
		overflow: hidden;
	}

	&__other-reports{
		margin: 0 auto;
		width: 95%;
	}
}
.okPost {
	min-height: 100px
}

.skeletonForReports {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background: #FFF;
	display: flex;
	justify-content: center;
	padding: 1rem;
	gap: 0.5rem;
	flex-direction: column;
	overflow: hidden;
	&__itself {
		width: 100%;
		border-radius: 8px;
		flex: 1;
		overflow: hidden;
		min-height: 30vh;
		max-height: 55vh;
		position: relative;
	}
}

@media screen and (max-width: 1600px) {
	.okPost {
		margin: 0 !important;
	}
	.main-page {
		grid-template-columns: 0.6fr 1.1fr 0.41fr
	}
}

@media screen and (max-width: 1300px) {
	.okPost {
		margin: 0 !important;
	}
	.main-page {
		grid-template-columns: 0.8fr 0.95fr 0.41fr
	}
}
</style>
