import { useUserStore } from "@/stores/User/user"
import { useRatingStore } from '@/stores/tops'
import { useChartStore } from '@/stores/chart'
import { useRegionsStore } from '@/stores/regions'
import { useEventStore } from "@/stores/events"
import { useHeaderStore } from "@/stores/header"
import { useSidebarStore } from "@/stores/sidebar"
import { useReportsStore } from "@/stores/reports"

const stores = {
	useUserStore,
	useRatingStore,
	useChartStore,
	useRegionsStore,
	useEventStore,
	useHeaderStore,
	useSidebarStore,
	useReportsStore
}
export default stores
