import {defineStore} from "pinia"
import api from '@/api'
import { Region } from '@/models'
import imgAllRegions from '@/assets/img/Общие регионы.png'
import stores from '@/stores/index'

interface State {
	regions: Region[];
	currentRegion: Region;
}

export const useRegionsStore = defineStore({
	id: 'regionsStore',
	state: () => ({
		regions: [],
		currentRegion: {
			icon_url: imgAllRegions,
			title: 'Все регионы',
			id: -1,
		} as Region
	}) as State,
	actions: {
		/**
		 * Получает список всех регионов от API и сохраняет его в состояние магазина.
		 * @async
		 * @return {Promise<boolean>} Возвращает true, если список регионов был успешно поззучен, иначе false.
		 */
		async getRegions() {
			try {
				const resp = await api.regionApi.getRegions()
				
				if (resp.status !== 200) throw Error('Server not available!')
				
				const regionsToSend: Region[] = resp.data
					.map(({icon_url, title, id}: Region) => ({
						icon_url,
						title,
						id,
						state: false
					}))
					.sort((a, b) => a.title.localeCompare(b.title))
				
				regionsToSend.unshift({
					icon_url: imgAllRegions,
					title: 'Все регионы',
					id: -1,
					state: false,
				})
				
				this.regions = regionsToSend
				
				return true
			} catch (error: any) {
				console.error('Error getting regions: ', error.message)
				return false
			}
		},
		clearAllRegionStates() {
			this.regions.forEach(el => el.state = false)
		},
		/**
		 * Локально изменяет состояние отдельного региона.
		 * @async
		 * @param {number | string} id - ID или имя региона.
		 */
		async changeRegionState(id: number | string) {
			const userStore = stores.useUserStore()
			this.initiateLoaders()
			
			if(userStore.user?.region) return
			
			const foundElement = this.regions.find(el => {
				return typeof id === 'number' ? el.id === id : el.title.toLowerCase() === id.toLowerCase()
			})
			
			if(foundElement) {
				this.clearAllRegionStates()
				foundElement.state = true
				this.currentRegion = foundElement
				await this.changeDataByFilters()
			}
		},
		/**
		 * Изменяет данные на основе текущих активных фильтров.
		 * @async
		 * @return {Promise<object>} Возвращает результаты запросов к API.
		 */
		async changeDataByFilters() {
			const { useReportsStore, useRatingStore } = stores
			const reportsStore = useReportsStore()
			const chartStore = useRatingStore()
			
			const [vkPosts, okPosts, otherPosts] = await Promise.all([
				reportsStore.getPosts('vk', 1, 10, true),
				reportsStore.getPosts('ok', 1, 10, true),
				reportsStore.getPosts('other', 1, 10, true),
			])
			
			await chartStore.getUserCharts()
			
			return { vkPosts, okPosts, otherPosts }
		},
		/**
		 * Инициирует загрузки на всех страницах.
		 */
		initiateLoaders() {
			const { useRatingStore, useReportsStore, useChartStore } = stores
			const topsStore = useRatingStore()
			const reportsStore = useReportsStore()
			const chartStore = useChartStore();
			
			[reportsStore.okList, reportsStore.otherList, reportsStore.vkList, chartStore]
				.forEach(loader => loader.isLoading = true)
			topsStore.isLoadingUsers = true
		}
	}
})
