<script setup lang="ts">
import HeaderCard from "@/components/reusable/HeaderCard.vue"
import {useHeaderStore} from "@/stores/header"

const headerStore = useHeaderStore()

headerStore.getHeaderData()

</script>

<template>
	<div class="header">
		<HeaderCard
			v-for="(el, i) in headerStore.cardsData"
			:key="i"
			:data="el"
		/>
	</div>
</template>

<style scoped lang="scss">
.header{
	display: flex;
	flex-direction: row;
	gap: 16px;
	padding: 20px;
}
</style>
