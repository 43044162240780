import axios from "@/api/axios"
import {useSidebarStore} from "@/stores/sidebar"
import {useRegionsStore} from "@/stores/regions"
import {useEventStore} from "@/stores/events"

export default {
	async getPosts(social_network_type: 'vk' | 'ok' | 'other', page = 1, limit = 10){
		try {
			const sidebarStore = useSidebarStore()
			const regionStore = useRegionsStore()
			const eventStore = useEventStore()
			let regionToSend = ''
			if(regionStore.currentRegion.id !== -1) regionToSend = `&region_id=${regionStore.currentRegion.id}`
			const filterToSend = sidebarStore.currentFilter.id.map(el => `&status_ids=${el}`).join('')
			const res = await axios.get(
				`/reports?event_id=${eventStore.currentEvent?.id}&page=${page}&limit=${limit}&social_network=${social_network_type}${filterToSend}${regionToSend}`
			)
			return {
				data: res.data,
				status: res.status
			}
		}
		catch (err: any) {
			return err.status
		}
	}
}
