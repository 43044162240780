<script setup lang="ts">
import {useSidebarStore} from "@/stores/sidebar"

defineProps({
	text: String,
	state: Boolean
})

const sidebarStore = useSidebarStore()
</script>

<template>
	<div class="deputyCard" @click="sidebarStore.setFilterOption(text || '')">
		<input type="radio" class="deputyCard__checkbox" :checked="state">
		<span class="deputyCard__text">{{ text }}</span>
	</div>
</template>

<style scoped lang="scss">
.deputyCard {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
	cursor: pointer;
  &__checkbox {
    width: 1.1rem;
    height: 1.1rem;
		accent-color: black;
		cursor: pointer;
  }
  &__text {
		font-size: 1rem;
		font-weight: 400;
		white-space: nowrap;
    text-transform: uppercase;
  }
}
</style>
