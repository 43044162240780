<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

import { Post } from '@/models/posts'
import { useReportsStore } from '@/stores/reports'

const props = defineProps<{
		source: Post
	}>()

const loaded = computed({
	get: () => {
		return useReportsStore().vkList.posts.find(el => el.post_id === props.source.post_id)?.loaded
	},
	set: (newValue) => {
		const _ = useReportsStore().vkList.posts.find(el => el.post_id === props.source.post_id)
		if (_) _.loaded = newValue
	}
})
const frame = ref()
onMounted(() => {
	postId(VK.Widgets.Post(`vk_post_${props.source.wall_id}_${props.source.post_id}`, props.source.wall_id, props.source.post_id, props.source.vk_hash))
	function postId(postId) {
		let counter = 0
		let _
		// eslint-disable-next-line prefer-const
		_ = setInterval(() => {
			counter += 1
			if (counter === 20) clearInterval(_)
			try {
				const test = typeof VK.Widgets.RPC[postId].frame
				loaded.value = test !== 'object'

				VK.Widgets.RPC[postId].frame.onload = () => {
					clearInterval(_)
					loaded.value = true
				}
			} catch (e) {
				throw e
			}
		}, 100)
	}
})
</script>

<template>
	<!--	<div-->
	<!--		:style="!loaded ? 'visibility: hidden' : ''"-->
	<!--		:id="`vk_post_${props.source.wall_id}_${props.source.post_id}`"-->
	<!--	/>-->
	<div
		:id="`vk_post_${props.source.wall_id}_${props.source.post_id}`"
	/>
</template>

<style scoped lang="scss">

</style>
