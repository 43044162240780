<script setup lang="ts">
import {useEventStore} from "@/stores/events"

defineProps({
	source: Object,
})

const eventStore = useEventStore()
</script>

<template>
	<div :class="source === eventStore.currentEvent ? 'menu__events_card__active' : 'menu__events_card'" @click="eventStore.setCurrentEvent(source)">
		{{ source.name.toUpperCase() }}
	</div>
</template>

<style scoped lang="scss">
.menu__events_card{
	width: 100%;
	height: fit-content;
	border-radius: 8px;
	padding: 16px;
	border: 1px solid #365E9C33;
	font-size: 0.9rem;
	overflow-wrap: anywhere;
	cursor: pointer;

	&__active {
		width: 100%;
		height: fit-content;
		border-radius: 8px;
		padding: 16px;
		color: white;
		background: var(--color-text-positive);
		border: 1px solid var(--color-text-positive);
		font-size: 0.9rem;
		overflow-wrap: anywhere;
    cursor: pointer;
	}
}
</style>
