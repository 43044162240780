<template>
	<div class="menu">
		<div
			class="menu__logo"
		>
			<img
				:src="mainIcon"
				alt="Логотип"
				class="menu__logo-img"
			>
			<div class="menu__logout" @click="userStore.logOut()">
				<img :src="logoutIcon" alt="Выход">
			</div>
		</div>
		<div class="menu__card" @click="menuClick($event)">
			<button
				v-if="!userStore.user?.is_mger"
				id="all"
				:class="sidebarStore.currentTaskOption === 'all' ? 'menu__button_flat-active' : 'menu__button_flat'"
				@click="sidebarStore.changeCurrentTaskOption($event.currentTarget.id)"
			>
				<span>ВСЕ ЗАДАЧИ</span>
			</button>
			<div v-if="!userStore.user?.is_mger" class="menu__buttons">
				<button
					id="electoral"
					:class="sidebarStore.currentTaskOption === 'electoral' ?
						'menu__buttons_button-active' :
						'menu__buttons_button'"
					:disabled="eventStore.events.filter(el => el.type === 'electoral').length === 0"
					@click="sidebarStore.changeCurrentTaskOption($event.currentTarget.id)"
				>
					ЭЛЕКТОРАЛЬНЫЕ ЗАДАЧИ
				</button>
				<button
					id="party"
					:class="sidebarStore.currentTaskOption === 'party' ?
						'menu__buttons_button-active' :
						'menu__buttons_button'"
					:disabled="eventStore.events.filter(el => el.type === 'party').length === 0"
					@click="sidebarStore.changeCurrentTaskOption($event.currentTarget.id)"
				>
					ПАРТИЙНЫЕ ЗАДАЧИ
				</button>
			</div>
			<div class="menu__flatButtons">
				<button
					id="regions"
					:class="
						sidebarStore.pickedButton  === 'regions'
							|| regionsStore.currentRegion.id !== -1 ? 'menu__button_flat-active' : 'menu__button_flat'"
					:disabled="userStore.user?.region"
					@click="sidebarStore.changePickedButton($event.currentTarget.id)"
				>
					<span>РЕГИОНЫ</span>
					<img
						v-if="!userStore.user?.region"
						:src="
							sidebarStore.pickedButton  === 'regions' ||
								regionsStore.currentRegion.id !== -1 ? arrowWhite : arrowBlack"
						:class="sidebarStore.pickedButton  === 'regions' && 'rotate90'"
						alt=""
					>
				</button>
				<div class="menu__filters">
					<SidebarsFilterCard
						v-for="el in sidebarStore.filters"
						v-if="!userStore.user?.is_mger"
						:text="el.text"
						:state="el.state"
						:modal-state="el.modalState"
					/>
					<SidebarsFilterCard
						v-for="el in sidebarStore.filters_mger"
						v-else
						:text="el.text"
						:state="el.state"
					/>
				</div>
			</div>
			<div class="menu__divider">События</div>
			<div class="menu__events">
				<MenuEventsCard v-for="el in eventsArray" :key="el.id" :source="el"/>
			</div>
		</div>
		<RegionsModal v-if="sidebarStore.regionsModal" />
	</div>
</template>

<script lang="ts" setup>
import mainIcon from "@/assets/img/vverh_logo.svg"
import logoutIcon from "@/assets/img/logout.svg"
import arrowWhite from "@/assets/img/arrow-up-white.svg"
import arrowBlack from "@/assets/img/arrow-up-black.svg"
import MenuEventsCard from "@/components/reusable/MenuEventsCard.vue"
import SidebarsFilterCard from "@/components/reusable/SidebarsFilterCard.vue"
import RegionsModal from "@/components/reusable/RegionsModal.vue"
import stores from '@/stores'
import {computed} from "vue"
import { useUserStore } from '@/stores/User/user'

const sidebarStore = stores.useSidebarStore()
const eventStore = stores.useEventStore()
const userStore = useUserStore()
const regionsStore = stores.useRegionsStore()

const eventsArray = computed(() => {
	if(sidebarStore.currentTaskOption === 'electoral') return eventStore.eventsElectoral
	if(sidebarStore.currentTaskOption === 'party') return eventStore.eventsParty
	return eventStore.events
})

const menuClick = (event: any) => {
	if(event.target === event.currentTarget) sidebarStore.backdropTap()
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.menu {
	height: 100vh	;
	width: 26rem;
	background-color: var(--color-bg-light);
	padding: 0 0 0 0;
	position: relative;

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 2rem;
		background-color: var(--color-bg-logo);
		width: auto;
		height: 150px;
		border-radius: 0 0 10px 10px;

		&-img {
			width: 12rem;
			height: 4.5rem;
		}
	}

	&__logout {
		display: flex;
		align-items: center;
		justify-content: center;
		background: white;
		width: fit-content;
		padding: 5px 5px 5px 3px;
		border-radius: 50%;
	}

	&__card {
		margin-top: 20px;
		width: calc(100% - 20px);
		float: right;
		border-radius: 10px;
		max-height: 80vh;
		padding: 10px;
		display: flex;
		flex-direction: column;
		background: var(--color-bg-highlight);
		z-index: 300;
		position: relative;
	}

	&__button_flat {
		border: 1px solid var(--color-text-positive);
		width: 100%;
		color: var(--color-main-text);
		border-radius: 8px;
		height: fit-content;
		font-size: 1rem;
		font-weight: 400;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 10px;
		padding: 2px 0;
		transition: var(--transition-time) ease-in-out;

		&-active {
			border: 1px solid var(--color-text-positive);
			background: var(--color-text-positive);
			width: 100%;
			color: white;
			height: fit-content;
			border-radius: 8px;
			font-size: 1rem;
			font-weight: 400;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 10px;
			padding: 2px 0;
			transition: var(--transition-time) ease-in-out;
		}

		&:disabled {
			border: 1px solid var(--color-disable);
			color: var(--color-text-sidebar-disabeld);
			background: var(--color-disable);
		}
	}

	&__buttons {
		display: flex;
		flex-direction: row;
		gap: 5px;
		margin-top: 10px;
		margin-bottom: 30px;
		&_button {
			font-size: 1rem;
			line-height: 1rem;
			font-weight: 400;
			border-radius: 10px;
			height: 75px;
			color: var(--color-main-text);
			border: 1px solid var(--color-text-positive);
			transition: var(--transition-time) ease-in-out;
			&-active {
				font-size: 1rem;
				line-height: 1rem;
				font-weight: 400;
				border-radius: 10px;
				color: white;
				height: 75px;
				transition: var(--transition-time) ease-in-out;
				background: var(--color-text-positive);
			}
			&:disabled {
				border: 1px solid var(--color-disable);
				color: var(--color-text-sidebar-disabeld);
				background: var(--color-disable);
			}
		}
	}

	&__filters{
		display: flex;
		flex-direction: column;
		gap: 5px;
	}

	&__divider {
		margin-top: 30px;
		color: var(--color-text-positive);
		font-size: 18px;
		margin-left: 13px;
		margin-bottom: 10px;
	}

	&__events {
		display: flex;
		flex-direction: column;
		gap: 10px;
		max-height: 28vh;
		overflow-y: auto;
	}

	&__flatButtons{
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	&__logo-text {
		color: var(--color-accent-light);
		font-size: 2rem;
		font-weight: 700;
	}

	&__navbar {
		display: flex;
		list-style: none;
		flex-direction: column;
		color: var(--color-text-light);
		margin-top: 2rem;
	}

	&__link {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 1rem;
		width: 19rem;
		padding: 1rem 0 1rem 2rem;
		border-radius: 5px 0 0 5px;

		&-img {
			width: 2rem;
			height: 2rem;
			object-fit: cover;
		}

		&-text {
			font-weight: 400;
			font-size: 1.3rem;
			line-height: 1.5rem;
		}
	}

	&__link.menu__link_type_child-link {
		padding: 0;
		width: auto;
		margin-left: -5px;
		margin-bottom: 1.5rem;

		.menu__link-text {
			line-height: 2rem;
		}
	}

	&__child-items {
		list-style: disc;
		margin-top: 0.5rem;
		padding-left: 5.9rem;
	}

	&__link {
		padding: 0.95rem 0 0.95rem 2rem;
		margin-left: auto;

		span {
			font-weight: 400;
			font-size: 1.3rem;
		}
	}

	&__item .menu__link_active {
		background-color: var(--color-bg-light);
		color: var(--color-accent);
	}

	&__child-item .menu__link_active {
		background-color: transparent;
		color: #FFFFFF;
	}

	&__child-item_active {
		color: #FFFFFF;
	}
}
.rotate90 {
	transform: rotate(90deg);
	animation: var(--transition-time) ease-in-out rotate90 forwards;
}
@keyframes rotate90 {
	from{
		transform: rotate(0);
	}
	to{
		transform: rotate(90deg);
	}
}
</style>
