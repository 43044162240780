import regionApi from "./regions"
import userApi from "./user"
import reportsApi from "./reports"
import topsApi from "./tops"
import headerApi from "./header"
import eventApi from './events'
import chartApi from "./chart"

const api = {
	regionApi,
	userApi,
	reportsApi,
	topsApi,
	headerApi,
	chartApi,
	eventApi
}
export default api
