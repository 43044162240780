<template>
	<div class="d-flex flex-column justify-space-between py-2">
		<div class="chart_header ma-0">
			<v-col cols="3" />
			<v-col
				class="ma-0 pa-0 d-flex justify-center align-center w-100"
			>
				<v-menu
					v-if="!useChartStore().isLoading"
					offset-y
					:close-on-content-click="false"
				>
					<template #activator="{ props }">
						<span
							style="background-color: #365E9C; border-radius: 10px; color: white"
							class="px-2"
							v-bind="props"
						>
							{{ `с ${new Date(datesMin).getDate()} ${monthA[new Date(datesMin).getMonth()]}, ${new Date(datesMin).getFullYear()}` }}
						</span>
					</template>
					<v-date-picker
						v-model="datesMin"
						:min="datesMinDate"
						:max="datesMax"
					/>
				</v-menu>
				<img
					v-if="!useChartStore().isLoading"
					src="@/assets/img/arrow.svg"
					class="mx-2"
					alt=""
				>
				<v-menu
					v-if="!useChartStore().isLoading"
					offset-y
					:close-on-content-click="false"
				>
					<template #activator="{ props }">
						<span
							style="background-color: #365E9C; border-radius: 10px; color: white"
							class="px-2"
							v-bind="props"
						>
							{{ `по ${new Date(datesMax).getDate()} ${monthA[new Date(datesMax).getMonth()]}, ${new Date(datesMax).getFullYear()}` }}
						</span>
					</template>
					<v-date-picker
						v-model="datesMax"
						:min="datesMin"
						:max="datesMaxDate"
					/>
				</v-menu>
			</v-col>
			<v-col
				cols="3"
				class="pa-1 d-flex justify-end"
			>
				<label
					class="pr-2 cursor-pointer"
					style="user-select: none;"
					@click="changeCumulative"
				>
					С накопительным
				</label>
				<Toggle
					:value="cumulative"
					class="toggle"
					:disabled="useChartStore().isLoading"
					@click="changeCumulative"
				/>
			</v-col>
		</div>
		<div v-if="!useChartStore().isLoading" style="height: 95%;">
			<Chart
				:options="chartData"
				style="height: 100%; max-width: 100%"
			/>
		</div>
		<div v-else style="position: relative">
			<DataLoader/>
		</div>
		<div
			class="ma-0 pa-0 d-flex justify-center"
		>
			<span class="chart_header_text myBlue">Количество пользователей</span>
			<span class="chart_header_text myGray">Количество действий</span>
		</div>
	</div>
</template>

<script setup lang="ts">
import '@vueform/toggle/themes/default.css'
import { computed, ref, watch } from 'vue'
import { Chart } from "highcharts-vue"
import stores from '@/stores'
import Toggle from '@vueform/toggle'
import {useEventStore} from "@/stores/events"
import {useChartStore} from "@/stores/chart"
import DataLoader from "@/components/reusable/DataLoader.vue"

const eventStore = useEventStore()

const monthA = 'января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря'.split(',')
const todayDate = new Date
const datesMaxDate = computed(() => {
	if(!eventStore.currentEvent?.end_at) return `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`
	return eventStore.currentEvent.end_at
})
const datesMinDate = computed(() => {
	if(!eventStore.currentEvent?.started_at) return `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`
	return eventStore.currentEvent?.started_at
})
const datesMin = computed({
	get: () => {
		return stores.useChartStore().chartDateFrom
	},
	set: (newValue: any) => {
		stores.useChartStore().chartDateFrom = newValue
	}
})

const datesMax = computed({
	get: () => {
		return stores.useChartStore().chartDateTo
	},
	set: (newValue: any) => {
		stores.useChartStore().chartDateTo = newValue
	}
})
const cumulative = computed({
	get: () => {
		return stores.useChartStore().cumulative
	},
	set: (newValue: any) => {
		stores.useChartStore().cumulative = newValue
	}
})
datesMin.value = new Date(datesMinDate.value)
datesMax.value = new Date(datesMaxDate.value)
stores.useChartStore().getChartData()

const currentFilter = computed(() => {
	return stores.useSidebarStore().currentFilter
})

const currentRegion = computed(() => {
	return stores.useRegionsStore().currentRegion.title
})

watch([datesMin, datesMax, currentFilter, currentRegion], ()=>{
	stores.useChartStore().getChartData()
}, { deep: true })

const categoriesChart = computed(() => {
	return stores.useChartStore().chartData.map((el) => {
		const date = new Date(el.date)
		return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
	})
})

const changeCumulative = () => {
	if(!useChartStore().isLoading) {
		cumulative.value = !cumulative.value
		stores.useChartStore().getChartData()
	}
}

const seriesChart = computed(() => {
	const countUsers = stores.useChartStore().chartData.map((el) => {
		return el.count_users
	})
	const countActions = stores.useChartStore().chartData.map((el) => {
		return el.count_reports
	})
	return [{
		name: 'Количество пользователей',
		color: '#2c749d',
		opacity: 1,
		index: 4,
		data: countUsers,
		dataLabels: {
			enabled: true,
			style: {
				fontSize: '.7rem'
			}
		}
	},
	{
		name: 'Количество действий',
		color: '#bcbcbc',
		opacity: 1,
		index: 2,
		data: countActions,
		dataLabels: {
			enabled: true,
			style: {
				fontSize: '.7rem'
			}
		}
	}]
})
	
const chartData = ref({
	chart: {
		pinchType: "x",
		panning: true,
		type: 'areaspline',
		events: {
			load: function(event) {
				event.target.reflow()
			}
		}
	},
	title: {
		text: null
	},
	legend: {
		enabled: false
	},
	xAxis: {
		categories: categoriesChart,
		min: 0.4,
		max: 7 - 1.4,
		labels: {
			style: {
				fontSize: '.7rem'
			}
		}
	},
	yAxis: {
		labels: {
			enabled: false
		},
		title: {
			text: null
		}
	},
	exporting: {
		enabled: false
	},
	tooltip: {
		useHTML: true,
		backgroundColor: 'rgba(255, 255, 255, 0.8)',
		borderWidth: 1,
		borderColor: '#365e9c',
		borderRadius: 5,
		style: {
			flexDirection: 'column',
			flexWrap: 'nowrap',
			alignItems: 'flex-start',
			padding: '5px',
			backgroundColor: 'rgba(255, 255, 255, 0.8)',
			boxShadow: '4px 4px 2px 1px #00000077',
			display: 'flex',
			fontSize: '0.8rem',
			fontStyle: 'Roboto'
		},
		headerFormat: '<p>{point.key}</p>',
		pointFormat: '<div style="margin: 0; display: flex; justify-content: flex-start; flex-wrap: nowrap">' +
				'<img style="background-color: {series.color}; margin: auto 0 9px 5px; border-radius: 100%" ' +
				'width="9px" height="9px" alt="" src=""/>' +
				'<p style="margin: 0 0 0 9px; text-wrap: none; text-align: center; font-weight: normal; font-style: normal; ">{series.name}: ' +
				'<span style="font-family: Roboto, serif;">{point.y}</span></p>' +
				'</div>',
		footerFormat: '',
		outside: true,
		shared: true
	},
	credits: {
		enabled: false
	},
	plotOptions: {
		series: {
			opacity: 1,
			stickyTracking: true,
			enableMouseTracking: true,
			fillOpacity: 1
		}
	},
	series: seriesChart
})

watch(() => seriesChart.value, () => {
	setTimeout(() => {
		chartData.value.xAxis.labels.step = 3
		chartData.value.xAxis.max = categoriesChart.value.length - 1
		chartData.value.xAxis.min = 0
	}, 0)
}, { deep: true })

</script>

<style scoped lang="scss">
	.toggle {
		--toggle-height: 1.25rem;
		--toggle-border: #ffffff 0.125rem;
		--toggle-font-size: 0.75rem;
		--toggle-duration: 150ms;
		--toggle-bg-off: #e5e7eb;
		--toggle-bg-on-disabled: #d1d5db;
		--toggle-bg-off-disabled: #e5e7eb;
		--toggle-border-off: #e5e7eb;
		--toggle-border-on-disabled: #d1d5db;
		--toggle-border-off-disabled: #e5e7eb;
		--toggle-ring-width: 3px;
		--toggle-text-on: #ffffff;
		--toggle-text-off: #0060ff;
		--toggle-text-on-disabled: #9ca3af;
		--toggle-text-off-disabled: #9ca3af;
		--toggle-handle-enabled: #ffffff;
		--toggle-handle-disabled: #6f8ac0;

		--toggle-width: 2.5rem;
		--toggle-border-on: #365e9c;
		--toggle-bg-on: #365e9c;
		--toggle-ring-color: #365e9c30;
	}
	.series {
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: flex-start;
		padding: 5px;
		background-color: rgba(255, 255, 255, 0.8);
		border-radius: 5px;
		border: 1px solid #365e9c;
		box-shadow: 4px 4px 2px 1px #00000077;
		display: flex;
	}

	.search {
		font-family: Roboto, sans-serif;
		border-radius: 10px;
		color: #BCBCBC;
	}

	.container {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin: 0;
	}

	.nav {
		display: flex;
		width: 100%;
		position: absolute;
		bottom: -2.8em;
		left: 0;
		padding: 0 10%;
		justify-content: space-between;
		margin: 0 auto;

		&-item {
			display: flex;
			justify-content: flex-start;
			text-transform: uppercase;
			align-items: flex-start;
			font-family: Meta Pro, serif;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			text-align: start;
			width: 100%;
			color: #c6c6c6;
			background-color: #fff;
			border-bottom: 4px solid #ededed;
			line-height: 100%;
			cursor: pointer;

			& > p {
				text-transform: uppercase;
				font-family: Meta Pro, serif;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				text-align: start;
				margin: 0;
			}

			&.active {
				color: black;
				border-bottom: 4px solid #72c2dd;
			}
		}
	}

	.chart {
		padding: 10px;
		margin: auto;
		width: 100%;
		//height: 330px;
		&_block {
			margin-bottom: 20px;
			background-color: #fff;
			border-radius: 10px;
		}
		&_header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: .8rem;

			&_color {
				margin-left: 25px;
				width: 5px;
				height: 5px;
				border-radius: 100%;
				margin-right: 5px;

				&.gray {
					background-color: #bcbcbc;
				}

				&.blue {
					background-color: #365e9c;
				}
			}

			&_text {
				font-family: "Meta Pro Medium",sans-serif;
				font-weight: normal;
				font-style: normal;
				color: white;
				padding: 1px 15px 0 15px;
				font-size: 14px;
				border-radius: 10px;
				margin: 0 10px;
				&.myBlue {
					background-color: #365e9c;
				}
				&.myGray {
					background-color: #bcbcbc;
				}
			}
		}
	}

	.scroll {
		overflow: auto;
		margin: 0;
	}
</style>
