<script setup lang="ts">
import arrowWhite from "@/assets/img/arrow-up-white.svg"
import arrowBlack from "@/assets/img/arrow-up-black.svg"
import { computed } from "vue"
import { useSidebarStore } from "@/stores/sidebar"
import SidebarDeputyFilterCard from "@/components/reusable/SidebarDeputyFilterCard.vue"
import { useUserStore } from "@/stores/User/user"

const props = defineProps({
	text: String,
	state: Boolean,
	modalState: {required: false, default: false}
})

const userStore = useUserStore()
const sidebarStore = useSidebarStore()

const callChangeModalState = (event: any) => {
	if(userStore.user?.is_mger) {
		sidebarStore.setFilterOption(props.text || '')
		return
	}
	if(event.target === event.currentTarget.children[0] || event.target === event.currentTarget || event.target === event.currentTarget.children[1]) {
		const filter = sidebarStore.filters.find(el => el.text === props.text)
		if(filter?.options) {
			if(filter.state && filter.modalState) {
				filter.state = false
				sidebarStore.backdropTap()
				return
			}
		}
		sidebarStore.changeModalState(props.text || '')
		sidebarStore.setFilterState(props.text || '')
		if(event.currentTarget.innerText === 'ВСЕ ПОЛЬЗОВАТЕЛИ') {
			sidebarStore.setFilterOption(props.text || '')
			return
		}
	}
}

const optionsToShow = computed(() => {
	return sidebarStore?.filters.find(el => el.text === props.text)?.options || []
})

</script>

<template>
	<div :class="state ? 'filterCard_active' : 'filterCard'" @click="callChangeModalState($event)">
		<div>{{ text }}</div>
		<img
			v-if="text !== 'все пользователи' && !userStore.user?.is_mger"
			:src="state ? arrowWhite : arrowBlack"
			:class="(state && modalState) && 'rotate90'"
		>
		<div v-if="modalState" class="filterCard__modal">
			<SidebarDeputyFilterCard v-for="(el, i) in optionsToShow" :key="i" :text="el.option" :state="el.state"/>
		</div>
	</div>
</template>

<style scoped lang="scss">
.filterCard {
	text-transform: uppercase;
	font-size: 0.9rem;
	font-weight: 400;
	color: var(--color-main-text);
	width: 100%;
	height: 4rem;
	padding: 1.1rem;
	border: 1px solid #365E9C33;
	background: white;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	transition: var(--transition-time) ease-in-out;
	position: relative;
	cursor: pointer;
	&_active {
		position: relative;
		text-transform: uppercase;
		font-size: 0.9rem;
		font-weight: 400;
		color: white;
		width: 100%;
    height: 4rem;
    padding: 1.1rem;
		border: 1px solid var(--color-text-positive);
		background: var(--color-text-positive);
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: var(--transition-time) ease-in-out;
		cursor: pointer;
	}
	&__modal{
		overflow: hidden;
		background: white;
		height: fit-content;
		width: fit-content;
		max-width: 25vw;
		text-overflow: ellipsis;
		position: absolute;
		left: calc(100% + 30px);
		top: 0;
		z-index: 300;
		color: var(--color-main-text);
		border-radius: 10px;
		padding: 30px;
		box-shadow: 0 4px 8px #081E4014;
		display: flex;
		flex-direction: column;
		gap: 2.1rem;
		cursor: initial;
	}
}

.rotate90 {
	transform: rotate(90deg);
	animation: var(--transition-time) ease-in-out rotate90 forwards;
}
@keyframes rotate90 {
	from{
		transform: rotate(0);
	}
	to{
		transform: rotate(90deg);
	}
}

</style>
