import { defineStore } from "pinia"
import {useReportsStore} from "@/stores/reports"
import {useRatingStore} from "@/stores/tops"
import {useEventStore} from "@/stores/events"
import {useUserStore} from "@/stores/User/user"
import stores from '@/stores/index'
import {useChartStore} from "@/stores/chart"

/**
 * Состояние в боковой панели VueStore, используемое для контроля и установки фильтров.
 *
 * @namespace useSidebarStore
 *
 * @property {Array} filters - Массив объектов, представляющих фильтры пользователя
 * @property {Array} filters_mger - Массив фильтров для пользователей Mger
 * @property {boolean} backdropState - Состояние подложки
 * @property {object} currentFilter - Текущий примененный фильтр
 * @property {boolean} regionsModal - Модальное состояние регионов
 * @property {string} pickedButton - Текущая выбранная кнопка
 * @property {string} currentTaskOption - Опция текущей задачи
 *
 * @function clearFilterStates - Очистите состояние фильтра полностью - его собственное состояние и его модальное окно
 * @function clearFilterStatesMger - Очищает все состояния для пользователей Mger
 * @function clearFilterOptions - Очистить все опции фильтра
 * @function clearModals - Закрывает все модальные окна и обрабатывает возможные события при его закрытии
 * @function setFilterOption - Устанавливает опцию фильтра
 * @function setFilterState - Устанавливает состояние фильтра
 * @function changeModalState - Находит элемент фильтра и меняет его состояние модального окна
 * @function changeBackdropState - Объявительное изменение состояния подложки
 * @function backdropTap - Действия при нажатии на подложку
 * @function changePickedButton - Изменяет состояние выбранных кнопок
 * @function changeCurrentTaskOption - Измените опцию текущей задачи
 * @function changeDataByFilters - Обновляет данные на странице в зависимости от примененных фильтров
 * @function initiateLoaders - Запускает все загрузчики и скелетоны на странице
 */
export const useSidebarStore = defineStore('sidebarStore',{
	state: () => ({
		// TODO написать модели
		filters: [
			{
				text: 'все пользователи',
				state: true,
				modalState: null,
				id: [] as Number[]
			},
			{
				text: 'активисты',
				state: false,
				modalState: false,
				options:[
					{option: 'Все активисты', state: false, id: [1, 3, 7, 8, 9, 10, 18, 11, 13, 31, 32, 33, 34, 35, 36]},
					{option: 'Единомышленники и сторонники Партии', state: false, id: [1, 3]},
					{option: 'Члены Партии и секретари ПО', state: false, id: [7, 8]},
					{option: 'Члены МПС и секретари МО', state: false, id: [10, 18, 9]},
					{option: 'Члены РПС и секретари РО', state: false, id: [11, 13]},
					{option: 'Члены МГЕР', state: false, id: [31, 32, 33, 34, 35, 36]},
				]
			},
			{
				text: 'депутаты',
				state: false,
				modalState: false,
				options:[
					{option: 'Все депутаты', state: false, id: [24, 23, 26, 22]},
					{option: 'Депутаты ГД', state: false, id: [24]},
					{option: 'Депутаты ЗС', state: false, id: [23]},
					{option: 'Депутаты АЦ', state: false, id: [26]},
					{option: 'Депутаты ОМСУ', state: false, id: [22]}
				]
			},
		], // все пользователи, активисты, депутаты
		// TODO написать модели
		filters_mger: [
			{text: 'Все пользователи', state: true, id: []},
			{text: 'Сторонник МГЕР', state: false, id: [36]},
			{text: 'Член МГЕР', state: false, id: [31]},
			{text: 'Руководитель МО МГЕР', state: false, id: [35]},
			{text: 'Руководитель РО и член РШ МГЕР', state: false, id: [33, 34]},
			{text: 'Член КС МГЕР', state: false, id: [32]},
		],
		backdropState: false,
		// TODO написать модель
		currentFilter: {text: 'все пользователи', state: true, id: [] as Number[]},
		regionsModal: false,
		pickedButton: '', // regions, filters
		currentTaskOption: 'all' // all, party, electoral
	}),
	actions:{
		/**
		 * @method clearFilterStates
		 * Сбросить все состояния фильтров и их модальных окон.
		 */
		clearFilterStates() { // Очищает полностью состояние фильтра - его собственное состояние и его модального окна
			this.filters.forEach(el => {
				if(el.options?.find(el => el.state)){
					el.modalState = false
					return
				}
				el.state = false
				el.modalState = false
			})
		},
		
		/**
		 * @method clearFilterStatesMger
		 * Сбросить все состояния фильтров для Mger пользователей.
		 */
		clearFilterStatesMger() { // Очищает все состояния у мгер пользователя
			this.filters_mger.forEach(el => {
				el.state = false
			})
		},
		
		/**
		 * @method clearFilterOptions
		 * Сбросить все параметры фильтров.
		 */
		clearFilterOptions(){ // Очищает все параметры фильтров
			this.filters.forEach(el => {
				if(el.text === 'все пользователи') {
					el.state = false
					return
				}
				el.options?.forEach((el) => {
					el.state = false
				})
			})
		},
		
		/**
		 * @method clearModals
		 * Закрывает все модальные окна и устанавливает обработчики на возможные события при их закрытии.
		 */
		clearModals(){ // Закрывает все модальные окна и обрабатывает возможные евенты при его закрытии
			this.regionsModal = false
			this.pickedButton = ''
			this.filters.forEach(el => el.modalState = false)
			if(this.filters[0].state) return
			const foundElement = this.filters.find(el => el.options?.find(option => option.state))
			if(foundElement) {
				this.clearFilterStates()
				foundElement!.state = true
			}
			else {
				this.clearFilterStates()
				if(useUserStore().user?.is_mger) return
				this.currentFilter = {text: 'все пользователи', state: true, id: []}
			}
		},
		
		/**
		 * @method setFilterOption
		 * Устанавливает опцию фильтра.
		 *
		 * @param {string} text - Текст опции фильтра.
		 */
		async setFilterOption(text: string){ // Устанавливает опцию фильтра
			if(this.currentFilter.option) { // Запрет на одинаковые запроса фильтра через модальные окна
				if(this.currentFilter.option === text) return
			}
			if(this.currentFilter.text === text) { // Запрет на одинаковые запроса фильтра
				if(useUserStore().user?.is_mger) return
				const foundElement = this.filters.find(el => el.text === text)
				foundElement!.state = true
				return
			}
			const userStore = useUserStore()
			this.initiateLoaders() // Запуск лоадеров и скелетонов при смене фильтра
			if(userStore.user?.is_mger) { // Проверка на мгер
				const foundOption = this.filters_mger.find(el => el.text === text)
				this.currentFilter = foundOption
				this.backdropTap()
				this.clearFilterStatesMger()
				foundOption!.state = true
				await this.changeDataByFilters()
				return
			}
			if(text === 'все пользователи') {
				const foundElement = this.filters.find(el => el.text === text)
				this.clearFilterOptions()
				this.currentFilter = foundElement
				foundElement!.state = true
				await this.changeDataByFilters()
				return
			}
			const foundOption = this.filters.reduce((result:any, el) => {
				const option = el.options?.find(option => option.option.toLowerCase() === text.toLowerCase())
				return option ? option : result
			}, undefined)
			this.clearFilterOptions()
			foundOption.state = true
			this.currentFilter = foundOption
			await this.changeDataByFilters()
		},
		
		/**
		 * @method setFilterState
		 * Устанавливает состояние фильтра.
		 *
		 * @param {string} text - Текст состояния фильтра.
		 */
		setFilterState (text: string) { // Устанавливает состояние фильтра
			const foundElement = this.filters.find(el => el.text.toLowerCase() === text.toLowerCase())
			if(foundElement) {
				this.filters.forEach((el) => {
					el.state = false
				}) // Закрытие всех фильтров
				foundElement.state = true // Открытие одного фильтра
				if(text === 'все пользователи') {
					this.changeBackdropState(false)
					return
				}
				if(foundElement.state && foundElement.options?.find(el => el.state) && this.backdropState) {
					if(foundElement.modalState) return // Баг при нажатии на один фильтр с включенной опцией, затем на другой, и снова на этот. Произойдёт отключение backdrop
					this.changeBackdropState(false)
					return
				} // Закрыть backdrop при нажатии на активный элемент с включенной опцией
				this.changeBackdropState(true)
				return
			}
			return
		},
		
		/**
		 * @method changeModalState
		 * Находит элемент фильтра и меняет его состояния модального окна.
		 *
		 * @param {string} text - Текст фильтра.
		 */
		changeModalState (text: string) { // Находит элемент фильтра и меняет его состояния модального окна
			this.clearModals()
			const foundElement = this.filters.find(el => el.text.toLowerCase() === text.toLowerCase())
			if(foundElement) {
				if(!foundElement.modalState) {
					this.filters.forEach((el) => {
						el.modalState = false
					}) // Закрытие всех модалок
					if(text === 'все пользователи') return
					foundElement.modalState = true
					return
				}
				foundElement.modalState = false
			}
		},
		
		/**
		 * @method changeBackdropState
		 * Изменить состояние подложки декларативно.
		 *
		 * @param {boolean} state - Состояние подложки.
		 */
		changeBackdropState(state: boolean) { // Декларотивное изменение состояния backdrop
			this.backdropState = state
		},
		
		/**
		 * @method backdropTap
		 * Обрабатывает нажатия на подложку.
		 */
		backdropTap() { // Действия при нажатии на backdrop
			this.clearFilterStates()
			this.changeBackdropState(false)
			this.clearModals()
			if(this.pickedButton === 'regions') this.pickedButton = ''
			if(!this.filters[0].state && !this.filters[1].state && !this.filters[2].state ) { // Установка значения на выбранный фильтр, в случае если все фильтры закрыты пользователем
				if(this.currentFilter.text === 'все пользователи') {
					this.filters[0].state = true
					return
				}
				if(useUserStore().user?.is_mger) return
				const foundOption = this.filters.find(el => el.options?.find(option => option.state))
				foundOption!.state = true
			}
		},
		
		/**
		 * @method changePickedButton
		 * Изменяет активную кнопку в фильтрах / регионах. Закрывает все модальные окна.
		 *
		 * @param {string} option - Выбранная кнопка.
		 */
		changePickedButton (option: string) { // Меняет состояние кнопок фильтры и регионы
			if(option === this.pickedButton) {
				this.pickedButton = ''
				this.changeBackdropState(false)
				this.clearModals()
				return
			}
			this.clearModals()
			if(option === 'filters') this.changeBackdropState(false)
			if(option === 'regions') {
				this.changeBackdropState(true)
				this.regionsModal = true
			}
			this.pickedButton = option
		},
		
		/**
		 * @method changeCurrentTaskOption
		 * Изменить выбранную опцию текущей задачи.
		 *
		 * @param {string} value - Опция задачи.
		 */
		async changeCurrentTaskOption(value: string) {
			if(value === this.currentTaskOption) return
			this.backdropTap()
			const eventStore = useEventStore()
			this.currentTaskOption = value
			await eventStore.getEvents()
		},
		
		/**
		 * @method changeDataByFilters
		 * Обновляет данные на странице в зависимости от применённых фильтров.
		 */
		async changeDataByFilters() { // Обновляет данные на страницы в зависимости от применённых фильтров
			const reportsStore = useReportsStore()
			const chartStore = useRatingStore()
			await chartStore.getUserCharts()
			if(chartStore.buttonsState.actionsButtonState) await chartStore.getRegionChart('reports')
			if(chartStore.buttonsState.usersButtonState)  await chartStore.getRegionChart('users')
			await reportsStore.getPosts('vk', 1, 10, true)
			await reportsStore.getPosts('ok', 1, 10, true)
			await reportsStore.getPosts('other', 1, 10, true)
			await useChartStore().getChartData()
		},
		
		/**
		 * @method initiateLoaders
		 * Запускает все загрузчики и скелетоны на странице.
		 */
		initiateLoaders() { // Запускает все лоадеры и скелетоны на странице
			const { useRatingStore, useReportsStore, useChartStore } = stores
			const topsStore = useRatingStore()
			const reportsStore = useReportsStore()
			const chartStore = useChartStore()
			topsStore.clearRegionsData();
			[reportsStore.okList, reportsStore.otherList, reportsStore.vkList, chartStore]
				.forEach(loader => loader.isLoading = true)
			topsStore.isLoadingUsers = true
			topsStore.isLoadingRegions = true
		}
	}
})
