import {defineStore} from "pinia"
import api from '@/api'
import type {User} from "@/models"
import {useRegionsStore} from "@/stores/regions"

/**
 * Представляет хранилище пользователя с действиями для входа в систему, выхода из системы и поддержания текущего пользователя.
 * @typedef {Object} UserStore
 * @property {User | null | undefined} user - Текущий пользователь. Может быть неопределенным, если пользователь еще не получен, или null, если пользователь вышел из системы.
 * @property {Function} logOut - Выполняет выход текущего пользователя из системы.
 * @property {Function} setCurrentUser - Устанавливает текущего пользователя, получая его с сервера.
 * @property {Function} authenticateUser - Аутентифицирует пользователя с предоставленным email и паролем.
 */
export const useUserStore = defineStore({
	id: 'userStore',
	state: () => ({
		user: undefined as User | null | undefined,
	}),
	actions: {
		/**
		 * Асинхронный метод, осуществляющий выход текущего пользователя из системы.
		 * В случае успешного ответа от API, текущий пользователь (`this.user`) устанавливается в `null`.
		 * Если происходит ошибка, выводится сообщение в консоли и возвращается `false`.
		 * @async
		 * @returns {Promise<boolean|void>} Возвращает `false` при ошибке. Если ответ от API успешен, возвращается `void`.
		 */
		async logOut(): Promise<boolean | void> {
			try {
				const res = await api.userApi.logout()
				if (res.status === 200) this.user = null
			} catch (err: unknown) {
				console.log(err)
				return false
			}
		},
		/**
		 * Асинхронный метод, осуществляющий задание текущего пользователя.
		 * Осуществляет запрос к API для получения текущего пользователя.
		 * Если ответ успешный и содержит данные, эти данные сохраняются в состоянии `this.user`.
		 * Если в ответе содержится регион, то он устанавливается в состоянии `currentRegion` хранилища `useRegionsStore`.
		 * В случае ошибки, выводится сообщение в консоли и `this.user` устанавливается в `null`.
		 * @async
		 */
		async setCurrentUser() {
			try {
				const res = await api.userApi.getCurrentUser()
				if (res && res.status === 200 && res.data) {
					if(res.data.region) {
						const regionStore = useRegionsStore()
						regionStore.currentRegion = res.data.region
					}
					this.user = res.data
				} else this.user = null
			} catch (err: unknown) {
				console.log(err)
				this.user = null
			}
		},
		/**
		 * Асинхронный метод для аутентификации пользователя.
		 * Принимает email и пароль и выполняет запрос на вход в систему к API.
		 * Если ответ успешный и содержит данные, пользователь записывается в состояние `this.user`, а метод возвращает `true`.
		 * Если ответ неудачный, метод возвращает `false`.
		 * @async
		 * @param {string} email - Email пользователя.
		 * @param {string} password - Пароль пользователя.
		 * @returns {Promise<boolean>} Возвращает `true` если аутентификация прошла успешно, иначе `false`.
		 */
		async authenticateUser(email: string, password: string): Promise<boolean> {
			try {
				const res = await api.userApi.login(email, password)
				if (res && res.status === 200 && res.data) {
					this.user = res.data
					return true
				} else return false
			} catch (err: unknown) {
				console.log(err)
				return false
			}
		}
	}
})
