import axios from "@/api/axios"

export default {
	async getRegions(): Promise<{ status: number; data: {
			title: string
			icon_url: string
			name: string, id: number}[] }> {
		try {
			const resp = await axios.get(`/regions`)
			return {
				data: resp.data,
				status: resp.status
			}
		} catch (err: any) {
			return err.response.status
		}
	}
}
