import axios from "@/api/axios"
import stores from "@/stores"
import { useSidebarStore } from "@/stores/sidebar"

/**
 * Универсальная функция для обработки успешных ответов от API.
 *
 * @param {any} response - Объект ответа API.
 * @returns {Object} Обработанная информация ответа с выделенными данными и статусом.
 */
const handleApiResponse = (response: any): object => {
	return {
		data: response.data,
		status: response.status,
	}
}

/**
 * Универсальная функция для обработки ошибочных ответов API.
 *
 * @param {any} err - Объект ошибки, предоставленный проваленным API запросом.
 * @returns {any} Статус ошибки.
 */
const handleApiError = (err: any): any => {
	return err.status
}

/**
 * Генерация строки запроса для API запросов.
 *
 * @param {any} eventStore - Хранилище, содержащее информацию о событиях.
 * @param {any} sidebarStore - Хранилище, содержащее информацию о боковой панели.
 * @param {any} [regionStore=null] - (Опционально) Хранилище, содержащее информацию о регионах.
 * @returns {string} Строка запроса, которую нужно дописать к конечной точке в API.
 */
const createQueryString = (
	eventStore: any,
	sidebarStore: any,
	regionStore: any = null
): string => {
	const params = new URLSearchParams()
	params.append("event_id", eventStore.currentEvent.id)
	const sidebarParams = sidebarStore.currentFilter.id
	if (sidebarParams.length !== 0) sidebarParams.forEach((el: number) => params.append("status_ids", String(el)))
	
	if (regionStore && regionStore.currentRegion.id != -1) {
		params.append("region_id", regionStore.currentRegion.id)
	}
	
	return params.toString()
}

export default {
	/**
	 * Получение данных графиков для регионов.
	 *
	 * @param {string} filter - Необходимая строка фильтра.
	 * @returns {Promise} Promise объект, представляющий либо данные, либо статус ошибки.
	 */
	async getRegionsCharts(filter: string): Promise<any> {
		try {
			const eventStore = stores.useEventStore()
			const sidebarStore = useSidebarStore()
			const queryString = createQueryString(eventStore, sidebarStore)
			const response = await axios.get(
				`/regions/rating?${queryString}&order_by=${filter}`
			)
			
			return handleApiResponse(response)
		} catch (err) {
			return handleApiError(err)
		}
	},
	
	/**
	 * Получение данных графиков для пользователя.
	 *
	 * @returns {Promise} Promise объект, представляющий либо данные, либо статус ошибки.
	 */
	async getUserCharts(): Promise<any> {
		try {
			const eventStore = stores.useEventStore()
			const sidebarStore = useSidebarStore()
			const regionStore = stores.useRegionsStore()
			const queryString = createQueryString(
				eventStore,
				sidebarStore,
				regionStore
			)
			const response = await axios.get(`/user/rating?${queryString}`)
			
			return handleApiResponse(response)
		} catch (err) {
			return handleApiError(err)
		}
	},
}
