import axios from './axios'

export default {
	async getEvents() {
		try {
			const resp = await axios.get(`/events`)
			return {
				data: resp.data,
				status: resp.status
			}
		}
		catch (err: any) {
			return err.status
		}
	}
}
