import axios from "@/api/axios"
import {ChartItem} from '@/models/chart'
import stores from '@/stores'

export default {
	async getChart(dateFrom: Date | undefined, dateTo: Date | undefined, cumulative = true){
		try {
			const regionToSend = stores.useRegionsStore().currentRegion.id !== -1 ? `&region_id=${stores.useRegionsStore().currentRegion.id}` : ''
			const filterToSend = stores.useSidebarStore().currentFilter.id.map(el => `&status_ids=${el}`).join('')
			const datesToSend = [
				dateFrom ? `&date_from=${dateFrom.getFullYear()}-${dateFrom.getMonth() + 1}-${dateFrom.getDate()}` : '',
				dateTo ? `&date_to=${dateTo.getFullYear()}-${dateTo.getMonth() + 1}-${dateTo.getDate()}` : ''
			].join('')
			const resp = await axios.get(
				`/chart?event_id=${stores.useEventStore().currentEvent?.id}${filterToSend}${regionToSend}${datesToSend}&cumulative=${cumulative}`
			)
			return {
				data: resp.data as ChartItem[],
				status: resp.status
			}
		} catch (err: any) {
			return err.response.status
		}
	},
}
