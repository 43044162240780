<template>
	<div style="height: 100vh;">
		<div v-if="store.user === undefined || eventStore.currentEvent === undefined">
			<DataLoader />
		</div>
		<LoginPage
			v-else-if="store.user === null || eventStore.currentEvent === null"
		/>
		<div
			v-else
			class="app"
		>
			<NavigationBar />
			<div class="asideNavbar">
				<div v-if="sidebarStore.backdropState" class="backdrop" @click="sidebarStore.backdropTap()"/>
				<Header />
				<animated-router-view />
			</div>
		</div>
	</div>
	<v-snackbar
		v-model="snackbar"
		location="top"
		:multi-line="true"
	>
		<v-row class="ma-0 pa-0 d-flex align-center">
			<p class="ma-0 pa-0">
				Пользователь {{ user.name }}
				<span style="font-family: Roboto, serif; font-size: .9em">{{ user.phone }}</span>
				не имеет прав на доступ к Информационному Дашборду, обратитесь к администратору системы
			</p>
		</v-row>

		<template #action="{ attrs }">
			<v-btn
				color="#365e9c"
				text
				v-bind="attrs"
				@click="snackbar = false"
			>
				Закрыть
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script setup lang="ts">
// Внешние библиотеки
import { ref, onMounted } from 'vue'

// Импортированные хранилища
import stores from '@/stores'

// Импортированные компоненты
import LoginPage from "@/views/LoginPage.vue"
import NavigationBar from "@/components/reusable/SideBar.vue"
import Header from "@/components/reusable/Header.vue"
import AnimatedRouterView from "@/components/reusable/AnimatedRouterView.vue"
import DataLoader from "@/components/reusable/DataLoader.vue"

// Утилиты
import { getCookie, deleteCookie } from '@/helpers/cookiesUtil'

const store = stores.useUserStore()
const eventStore = stores.useEventStore()
const sidebarStore = stores.useSidebarStore()

const snackbar = ref(false)
const user = ref(null)

onMounted(async () => {
	await store.setCurrentUser()
	await eventStore.getEvents()

	if (getCookie('error')) {
		user.value = JSON.parse(getCookie('error')!).user
		snackbar.value = true
		deleteCookie('error')
	}
})
</script>
<style lang="scss">
.app {
	height: 100%;
	width: 100%;
	display: flex;
	overflow: hidden;
	background-color: var(--color-bg-light);

	&__wrapper {
		width: calc(100% - 20rem);
		padding: v-bind("wrapperPadding");
	}

	&__title {
		font-weight: 700;
		font-size: 2rem;
		line-height: 4rem;
		text-transform: uppercase;
		text-align: left;
		position: relative;
		margin-bottom: 2rem;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1.5px;
			background-color: var(--color-accent);
		}
	}
	.asideNavbar {
		width: 100%;
	}
}
.backdrop {
	background: var(--color-backdrop);
	position: absolute;
	inset: 0;
	z-index: 250;
}
</style>
