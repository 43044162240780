<script setup lang="ts">
import {useUserStore} from "@/stores/User/user"
import {computed} from "vue"
import {useHeaderStore} from "../../stores/header"

const props = defineProps({
	data: [] as any
})

const userStore = useUserStore()

const mgerTextBelow = computed(() => {
	console.log(props.data.name)
	switch (props.data.name.toUpperCase()){
		case 'Активных членов МГЕР'.toUpperCase(): return 'зарегистрированных'
		case 'ДЕЙСТВИЯ МГЕР'.toUpperCase(): return 'от действий пользователей «ВВЕРХ»'
		case 'ДЕЙСТВИЯ МГЕР (СОБЫТИЯ ОРГАНИЗАЦИИ)'.toUpperCase(): return 'от общего количества действий МГЕР'
	}
})

const percents = computed(() => {
	if(props.data.name.toUpperCase() === 'ДЕЙСТВИЯ МГЕР (СОБЫТИЯ ОРГАНИЗАЦИИ)'.toUpperCase()) return props.data.relative_by_mger_actions_current_year
	if(props.data.name.toUpperCase() === 'ДЕЙСТВИЯ МГЕР'.toUpperCase()) return props.data.relative_by_all_actions_current_year
	return props.data.relative
})
</script>

<template>
	<div v-if="!userStore.user?.is_mger" class="headerCard">
		<h1>{{ data.name.toUpperCase() }}</h1>
		<h2>{{ data.count?.toLocaleString().split(',').join(' ') }}</h2>
		<h3 :class="data.delta_day_percent < 0 && 'headerCard__text_negative'">
			{{ data.delta_day?.toLocaleString().split(',').join(' ') }}
			({{ data.delta_day_percent > 0 ? '+' : '' }}{{ data.delta_day_percent }}%) за сутки
		</h3>
		<h3 :class="data.delta_week_percent < 0 && 'headerCard__text_negative'">
			{{ data.delta_week ?.toLocaleString().split(',').join(' ') }}
			({{ data.delta_week_percent > 0 && '+' }}{{ data.delta_week_percent }}%) за неделю
		</h3>
		<h3>
			{{ data.delta_month ?.toLocaleString().split(',').join(' ') }} ({{ data.delta_month_percent > 0 && '+' }}{{ data.delta_month_percent }}%) за месяц
		</h3>
	</div>
	<div v-else-if="data?.count" class="headerCard">
		<h1>{{ data.name.toUpperCase() }}</h1>
		<h2>{{ data['90_days']?.toLocaleString().split(',').join(' ') }} <p>(за 3 мес.)</p></h2>
		<h3>
			{{ data.current_month ?.toLocaleString().split(',').join(' ') }}
			<span :class="data.current_month > data.prev_month ? 'headerCard__text_positive' : 'headerCard__text_negative'">({{ data.current_month - data.prev_month > 0 ? '+' : '' + (data.current_month - data.prev_month)?.toLocaleString().split(',').join(' ') }})</span> за текущий месяц <br>
			{{ percents.toFixed(2) }}% {{ mgerTextBelow }}
		</h3>
	</div>
	<div class="headerCard" v-else>
		<h1>{{ data.name.toUpperCase() }}</h1>
		<h2>{{ data['90_days']?.toLocaleString().split(',').join(' ') }} <p>(за 3 мес.)</p></h2>
		<h3>
			{{ data.current_month ?.toLocaleString().split(',').join(' ') }}
			<span :class="data.current_month > data.prev_month ? 'headerCard__text_positive' : 'headerCard__text_negative'">({{ data.current_month - data.prev_month > 0 ? '+' : '' + (data.current_month - data.prev_month)?.toLocaleString().split(',').join(' ') }})</span> за текущий месяц <br>
			{{ useHeaderStore().mgerActiveRegistered?.toLocaleString().split(',').join(' ') }} {{ mgerTextBelow }}
		</h3>
	</div>
</template>

<style scoped lang="scss">
.headerCard{
	display: flex;
	flex-direction: column;
	height: auto;
	padding: 1rem 0;
	flex: 1;
	background: var(--color-bg-highlight);
	border-radius: 10px;
	align-items: center;
	justify-content: center;
	line-height: 16px;
	box-shadow: 0 4px 8px #081E4014;
	text-align: center;

	h1 {
		font-size: 1.5rem;
		font-weight: 400;
		font-family: FiraSans, sans-serif;
		color: var(--color-main-text);
		line-height: 1.5rem;
	}

	h2 {
		font-size: 1.5rem;
		font-weight: 900;
		color: var(--color-text-positive);
		line-height: 1.5rem;
		margin: 0.2rem 0;
		p {
			text-align: center;
			font-weight: 400;
			font-size: 0.8rem;
		}
	}

	h3 {
		font-weight: 400;
		color: var(--color-text-positive);
		font-size: 0.8rem;
	}

	&__text_negative{
		color: var(--color-text-negative) !important;
	}

	&__text_positive {
		color: var(--color-text-greater-than-zero) !important;
	}
}
@media screen and (max-width: 1600px) {
	.headerCard {
		padding: 0.5rem 0;

		h2 {
			margin: 0;
		}
	}
}
@media screen and (min-width: 2000px) {
	.headerCard {

		h2 {
			margin: 0.5rem;
		}
	}
}
@media screen and (min-width: 3000px) {
	.headerCard {

		h2 {
			margin: 0.7rem;
		}
	}
}
</style>
