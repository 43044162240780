import {defineStore} from "pinia"
import { useChartStore } from '@/stores/chart'
import { Event } from '@/models'
import api from '@/api'
import store from '@/stores/index'

export const useEventStore = defineStore({
	id: 'eventStore',
	state: () => ({
		events: [] as Event[],
		eventsParty: [] as Event[],
		eventsElectoral: [] as Event[],
		currentEvent: undefined as Event | undefined | null
	}),
	actions:{
		async getEvents() {
			try {
				const resp = await api.eventApi.getEvents()
				
				// Проверка статуса ответа и наличия данных
				if (resp.status !== 200 || !resp.data || !Array.isArray(resp.data)) {
					this.currentEvent = null
					return false
				}
				
				// Определение списков событий
				this.events = resp.data
				this.eventsParty = []
				this.eventsElectoral = []
				
				// Одиночное прохождение по списку событий и распределение их по категориям
				for (const event of this.events) {
					if (event.type === 'party') {
						this.eventsParty.push(event)
					} else if (event.type === 'electoral') {
						this.eventsElectoral.push(event)
					}
				}
				
				// Проверка наличия элементов в массиве перед присвоением первого элемента в переменную currentEvent.
				this.currentEvent = this.events.length > 0 ? this.events[0] : null
				
				// Проверка, что currentEvent не undefined или null перед установкой дат
				if (this.currentEvent) {
					useChartStore().setChartDates(this.currentEvent.started_at, this.currentEvent.end_at)
				}
				
				return true
			} catch (error) {
				// обработка ошибок
				console.error(error)
				this.currentEvent = null
				return false
			}
		},
		
		async setCurrentEvent(el: Event) {
			if (this.currentEvent !== el) {
				this.currentEvent = el
				store.useSidebarStore().initiateLoaders() // Неуверен что нужно запускать лоадеры полностью во всём приложении
				await store.useSidebarStore().changeDataByFilters()
			}
		}
	}
})
