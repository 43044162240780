<script setup lang="ts">
import UserCard from "@/components/reusable/UserCard.vue"
import arrowDown from '@/assets/img/arrow-down-circle-black.svg'
import {computed, ref} from "vue"
import {useRatingStore} from "@/stores/tops"
import RegionCard from "@/components/reusable/RegionCard.vue"

const emit = defineEmits(['changeShowTabs'])

const props = defineProps({
	showTabs: Boolean,
	id: String, // refers to defining correct state
})

const tabVertical = ref(null as null | HTMLBaseElement)
const regionChart = ref(null as null | HTMLBaseElement)
const regionCard = ref(null as null | HTMLBaseElement)

const calcHeightOfTopUsers = computed(() => {
	if (!tabVertical.value) return 0
	return tabVertical.value.offsetHeight
})
const calcHeightOfRegions = computed(() => {
	if (!tabVertical.value || !regionChart.value || !regionCard.value) return 0
	return tabVertical.value.offsetHeight * 3 + regionChart.value.offsetHeight + regionCard.value.offsetHeight + 13 * 3
})

const userCards = ref()
const upperButton = () => {
	userCards.value.style = 'max-height: 100vh'
	emit('changeShowTabs', props.id)
}

const pageHeight = computed(() => {
	return document.body.scrollHeight
})

const test = computed(() => {
	return pageHeight.value <= 1100 ? '690px' : '100%'
})

const title = computed(() => {
	if(props.id === 'userTopState') return 'топ-10 Пользователей'
	if(props.id === 'leadRegionsTopState') return 'топ-10 регионов-лидеров'
	return 'топ-10 регионов-аутсайдеров'
})

const chartsStore = useRatingStore()

// const actionsButtonState = ref(true)

const dataToShow = computed(() => {
	if(props.id === 'userTopState')	return []
	if(props.id === 'leadRegionsTopState')	return chartsStore.top
	return chartsStore.outside
})

chartsStore.getUserCharts()
chartsStore.defineGetRegionChart(title.value)
</script>

<template>
	<div :class="showTabs ? 'tab-vertical_active' : 'tab-vertical'">
		<button
			ref="tabVertical"
			:class="showTabs ? 'tab-vertical__button_active' :
				'tab-vertical__button'"
			@click="upperButton"
		>
			{{ title }}
			<img
				v-if="!showTabs"
				:src="arrowDown"
			>
		</button>
		<div
			ref="userCards"
			class="tab-vertical__userCards"
			:style="[showTabs ? {maxHeight: `calc(100% - ${props.id === 'userTopState' ? calcHeightOfTopUsers : 0}px)`, overflow: props.id === 'userTopState' ? 'auto' : 'hidden'} : {maxHeight: 0}]"
		>
			<div
				v-if="showTabs && props.id === 'userTopState'"
				class="usersWrapper"
				:style="{height: 'calc(100%)'}"
			>
				<template
					v-for="el in chartsStore.user"
				>
					<UserCard
						v-if="showTabs && props.id === 'userTopState' && chartsStore.user.length !== 0"
						:key="el.user_id"
						:name="el.fio"
						:avatar="el.avatar"
						:region_title="el.region_title"
						:status="el.status"
						:count_reports="el.count_reports"
						:is-loading="chartsStore.isLoadingUsers"
					/>
				</template>
				<div v-if="chartsStore.user.length === 0 && !chartsStore.isLoadingUsers" style="padding: 2rem; text-align: center">
					Похоже ничего не найдено
				</div>
				<div v-if="chartsStore.isLoadingUsers && chartsStore.user.length === 0">
					<UserCard
						v-for="el in 10"
						:key="el"
						:is-loading="true"
					/>
				</div>
			</div>
			<div
				v-if="showTabs && props.id !== 'userTopState'"
				class="regionChartWrapper"
			>
				<div
					ref="regionChart"
					class="regionChart"
				>
					<div class="regionChart__buttons">
						<button
							id="actions"
							:class="!chartsStore.buttonsState.actionsButtonState ? 'regionChart__buttons_inactive' : 'regionChart__buttons_active'"
							@click="chartsStore.changeButtonState(title,$event.target.id)"
						>
							Действия
						</button>
						<button
							id="users"
							:class="!chartsStore.buttonsState.usersButtonState ? 'regionChart__buttons_inactive' : 'regionChart__buttons_active'"
							@click="chartsStore.changeButtonState(title,$event.target.id)"
						>
							Пользователи
						</button>
					</div>
				</div>
				<div ref="regionCard">
					<RegionCard
						key="topCard"
						title="Регион"
						:actions="chartsStore.buttonsState.actionsButtonState ? 'Действия' : 'Пользователи'"
					/>
				</div>
				<div
					class="regionsWrapper"
					:style="{maxHeight: `calc(80vh - ${calcHeightOfRegions}px)`}"
				>
					<template
						v-for="el in dataToShow"
						v-if="!chartsStore.isLoadingRegions"
						:key="el.id"
					>
						<div>
							<RegionCard
								:title="el.title"
								:actions="chartsStore.buttonsState.actionsButtonState ? el.count_reports : el.count_report_users"
							/>
						</div>
					</template>
					<div v-for="el in 10" v-if="chartsStore.isLoadingRegions" :key="el">
						<RegionCard :is-loading="true"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.tab-vertical {
	color: var(--color-main-text);
	box-shadow: 0 4px 5px #00000014;
	background: var(--color-bg-highlight);
	border-radius: 10px;


	&_active {
		color: var(--color-main-text);
		box-shadow: 0 4px 5px #00000014;
		background: var(--color-bg-highlight);
		height: fit-content;
		border-radius: 10px;
		overflow: hidden;
	}

	&__button {
		font-size: .8rem;
		color: var(--color-main-text);
		font-weight: 400;
		padding: 0.68rem 20px;
		width: 100%;
		text-align: left;
		align-items: center;
		display: flex;
		justify-content: space-between;
		transition: var(--transition-time);
		text-transform: uppercase;

		&_active {
			font-size: .8rem;
			color: var(--color-main-text);
			font-weight: 400;
			padding: 0.68rem 20px;
			border-bottom: 4px solid var(--color-picked-tab);
			width: 100%;
			text-align: left;
			align-items: center;
			display: flex;
			justify-content: space-between;
			transition: var(--transition-time);
			text-transform: uppercase;
		}
	}
	&__userCards {
		overflow: auto;
		transition: max-height 0.35s linear;
	}
}

.regionChartWrapper {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.regionsWrapper {
	overflow-y: auto;
}
.usersWrapper {
  overflow-y: auto;
	overflow-x: hidden;
  transition: none;
}
.regionChart {
	flex-shrink: 0;

	&__buttons {
		display: flex;
		flex-direction: column;
		gap: 8px;
		margin-top: 20px;
		margin-bottom: 13px;
		padding: 0 1.5rem;

		&_active {
			background: var(--color-chart-button);
			border: 1px solid var(--color-chart-button);
			border-radius: 20px;
			font-size: 0.9rem;
			transition: var(--transition-time) ease-in-out;
		}

		&_inactive {
			border: 1px solid var(--color-chart-button);
			border-radius: 20px;
			font-size: 0.9rem;
			transition: var(--transition-time) ease-in-out;
		}
	}
}
</style>
