<template>
	<router-view v-slot="{ Component }" :key="$route.path + ( name + ($route.query[name]))" :name="name">
		<transition
			appear
			:name="isUndefined(name) ? 'scale': 'fade'"
			mode="out-in"
		>
			<component :is="Component" />
		</transition>
	</router-view>
</template>

<script lang="ts">
import { isUndefined } from 'lodash'

export default {
	name: "AnimatedRouterView",
	props: {
		name: String
	},
	methods: {
		isUndefined(element: any) {
			return isUndefined(element)
		}
	}
}
</script>

<style scoped lang="scss">
.fade-enter-active {
	transition: opacity 0.2s ease;
}

.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.scale-enter-active,
.scale-leave-active {
	transition: all 0.5s ease;
}

.scale-enter-from,
.scale-leave-to {
	opacity: 0;
	transform: scale(0.95);
}
</style>
